exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-blog-2024-docker-registry-access-control-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/blog/2024/docker-registry-access-control/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-blog-2024-docker-registry-access-control-index-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-photography-tsx": () => import("./../../../src/pages/photography.tsx" /* webpackChunkName: "component---src-pages-photography-tsx" */),
  "component---src-pages-work-akoe-tsx": () => import("./../../../src/pages/work/akoe.tsx" /* webpackChunkName: "component---src-pages-work-akoe-tsx" */),
  "component---src-pages-work-index-tsx": () => import("./../../../src/pages/work/index.tsx" /* webpackChunkName: "component---src-pages-work-index-tsx" */),
  "component---src-pages-work-that-haneul-tsx": () => import("./../../../src/pages/work/that-haneul.tsx" /* webpackChunkName: "component---src-pages-work-that-haneul-tsx" */)
}

